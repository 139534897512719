<template>
  <authorize permission="relation.view">
    <div
      v-loading="isLoading"
      class="Profile has-background-white p-a-s"
    >
      <template v-if="relation">
        <div class="Profile__header columns">
          <div class="column is-3 is-flex is-aligned-center">
            <round-avatar
              :src="relation.picture_url"
              :acronym-fallback="relation.preferred_name"
              :fallback="$asset('/images/general/generic_avatar_placeholder.jpg')"
              size="x-large"
            />
          </div>

          <div class="column is-9">
            <div class="Profile__header__details">
              <div class="is-flex is-aligned-justify">
                <h3 class="is-size-3 has-text-weight-bold" data-testid="relationName">
                  {{ relation.preferred_name }}
                </h3>
              </div>
            </div>
          </div>
        </div>

        <dashboard-filters-v2 class="m-b-s">
          <template #secondary>
            <dashboard-filters-v2-item
              v-for="link in links"
              :key="link.label"
            >
              <router-link
                :to="link.to"
                :data-testid="link.label"
                exact-active-class="has-text-primary"
              >
                <dashboard-filters-icon
                  :icon="link.icon"
                  :is-dropdown="false"
                  :label="$t(link.label)"
                />
              </router-link>
            </dashboard-filters-v2-item>

            <div class="DashboardFiltersV2-ButtonsContainer">
              <router-link
                :to="{ name: 'emails.new', query: { recipient } }"
                class="button is-black"
                data-testid="relationComposeEmail"
              >
                {{ $t('general.contact') }}
              </router-link>
            </div>
          </template>
        </dashboard-filters-v2>

        <div class="columns Profile__content border-t-black is-marginless">
          <div class="column is-3 Profile-sidebar-wrapper">
            <sidebar :relation="relation" />
          </div>

          <div class="column is-9">
            <router-view :relation="relation" />
          </div>
        </div>
      </template>
    </div>
  </authorize>
</template>

<script>
import { mapGetters } from 'vuex'

import { buildUrlWithParams } from '@hypefactors/shared/js/utils/UrlUtils'

import Sidebar from '@/components/connect/_shared/Sidebar'
import { Relation as RelationModel } from '@/models/Connect/Relation'

export default {
  components: {
    Sidebar
  },

  data: () => ({
    relation: null,
    isLoading: false,
    links: [
      { label: 'general.about', to: { name: 'connect.relation.view' }, icon: 'about' },
      { label: 'general.activity', to: { name: 'connect.relation.activity' }, icon: 'activity' },
      { label: 'general.coverage', to: { name: 'connect.relation.coverage' }, icon: 'eye' }
      // { label: 'general.related', to: { name: 'connect.relation.related' }, icon: 'related' }
    ]
  }),

  computed: {
    ...mapGetters(['filtersQuery', 'getCountryByIso2']),

    recipient () {
      if (!this.relation) {
        return ''
      }

      return JSON.stringify({
        relation_id: this.relation.id,
        name: this.relation.preferred_name
      })
    }
  },

  watch: {
    activeBrandId () {
      this.getRelation()
    }
  },

  mounted () {
    this.getRelation()
  },

  methods: {
    getRelation () {
      if (this.isLoading) {
        return
      }

      this.isLoading = true

      const endpoint = buildUrlWithParams(`/connect/relations/${this.$route.params.relationId}`, {
        brand: this.activeBrandId,
        include: ['stats']
      })

      this.$api.getData(endpoint)
        .then(response => {
          this.relation = new RelationModel(response)
        })
        .catch((error) => {
          this.$displayRequestError(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.Profile {
  &__header {
    &__details {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      p {
        display: flex;
        font-size: $hf__font-label-small;
        color: $hf__text-muted;
        align-items: center;
      }

      p:first-of-type {
        margin-bottom: $margin;
        font-size: $hf__font-default;
      }
    }
  }

  &__content {
    > .columns:first-of-type {
      border-bottom: 1px solid $grey;
    }

    .Profile-sidebar-wrapper {
      border-right: 1px solid $grey;
      @include rtl {
        border-left: 1px solid $grey;
        border-right: unset;
      }
    }
  }
}
</style>
