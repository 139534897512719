<template>
  <div class="Profile-sidebar">
    <h4 class="m-b-m is-flex is-aligned-justify">
      <span>{{ $t('general.contact') }}</span>
      <router-link
        :to="{ name: 'relations.edit', params: { relationId: relation.id } }"
        class="has-text-primary"
        data-testid="relationEdit"
      >
        {{ $t('forms.edit') }}
        <v-icon type="pencil" />
      </router-link>
    </h4>

    <p
      v-if="relation.employment && relation.employment.title"
      class="Profile-sidebar__contact-info"
    >
      <v-icon type="person" />
      <span>{{ relation.employment.title }}</span>
    </p>

    <p
      v-if="relation.employment && relation.employment.company"
      class="Profile-sidebar__contact-info"
    >
      <v-icon type="pencil" />
      <span>{{ relation.employment.company }}</span>
    </p>

    <v-button
      v-if="isDataGdprProtected"
      v-loading="sending"
      :class="{
        'is-dark': buttonState === 0,
        'is-success': buttonState === 1,
        'is-danger': buttonState === -1
      }"
      :disabled="buttonState !== 0"
      class="is-fullwidth has-height-auto"
      @click="onRequestContactInfo"
    >
      {{ buttonText }}
    </v-button>
    <div v-else>
      <!-- <p
        v-if="relation.contact.link"
        class="Profile-sidebar__contact-info"
      >
        <v-icon type="laptop" />
        <span>{{ relation.contact.link }}</span>
      </p> -->

      <span v-if="relation.phones && relation.phones">
        <p
          v-for="(phone, index) in relation.phones"
          :key="index"
          class="Profile-sidebar__contact-info"
        >
          <v-icon type="phone" />
          <span>{{ phone }}</span>
        </p>
      </span>

      <span v-if="relation.emails && relation.emails">
        <p
          v-for="(email, index) in relation.emails"
          :key="index"
          class="Profile-sidebar__contact-info"
        >
          <v-icon type="email" />
          <span>{{ email }}</span>
        </p>
      </span>
    </div>

    <p v-if="relation.location_country" class="Profile-sidebar__contact-info m-t-s">
      <country-flag :country="relation.location_country" class="nationality" />
      <span>{{ getCountryNameByIsoCode2(relation.location_country) }}</span>
    </p>

    <social-profiles
      :source="relation"
      :secondary-only="true"
      class="m-t-l"
    />

    <v-separator color="grey-dark" />

    <div class="has-text-weight-bold m-b-s">
      <v-icon type="heart" />
      {{ $t('general.categories') }}
    </div>

    <template v-if="relation.categories && relation.categories.length">
      <span
        v-for="category in categories"
        :key="category"
        class="Tag"
      >
        {{ category }}
      </span>
    </template>
    <span
      v-else
      class="fallback"
    >
      ...
    </span>

    <v-separator color="grey-dark" />

    <div class="has-text-weight-bold m-b-s">
      <v-icon type="tag" />
      {{ $t('general.tags') }}
    </div>

    <template v-if="relation.tags && relation.tags.length">
      <span
        v-for="topic in relation.tags"
        :key="topic"
        class="Tag is-light"
      >
        {{ topic }}
      </span>
    </template>
    <span
      v-else
      class="fallback"
    >
      ...
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import SocialProfiles from '@/components/connect/_shared/SocialProfiles'

export default {
  components: {
    SocialProfiles
  },

  props: {
    relation: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      sending: false,
      buttonState: 0
    }
  },

  computed: {
    ...mapGetters(['getCountryNameByIsoCode2']),

    categories () {
      return this.relation.categories.map((category) => this.$t(`connect-categories.${category}`))
    },

    buttonText () {
      if (this.buttonState === 1) {
        return this.$t('pages.relations.profile.request_info_success')
      }

      if (this.buttonState === -1) {
        return this.$t('pages.relations.profile.request_info_failure')
      }

      return this.$t('pages.relations.profile.request_info_btn')
    },

    isDataGdprProtected () {
      return this.relation.gdpr_protected === true
    }
  },

  methods: {
    onRequestContactInfo () {
      this.sending = true
      this.$api.post(`/connect/relations/${this.id}/request-contact-information`)
        .then(() => {
          this.buttonState = 1
        })
        .catch(() => {
          this.buttonState = -1
        })
        .finally(() => {
          this.sending = false
        })
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.Profile-sidebar {
  h4 {
    font-weight: bold;
    text-transform: uppercase;
  }

  a.disabled {
    pointer-events: none;
    cursor: default;
    color: $grey;
  }

  h4:not(:first-of-type) {
    margin-top: $margin;
  }

  &__contact-info {
    display: flex;
    align-items: center;
    margin-bottom: $margin-tiny;

    i {
      width: 16px;
      text-align: center;
      color: $hf__text-muted;
      margin-right: $margin-small;
      @include rtl {
        margin-left: $margin-small;
        margin-right: unset;
      }
    }

    span, & > a {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & > span {
      margin-left: 5px;
    }
  }
}
</style>
